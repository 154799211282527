import React from 'react'
import MUiButton  from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton';
import MUiFab from '@material-ui/core/Fab'
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '../Tooltip'
import colors from '../../variables/colors'

const useStyles = makeStyles( theme => ({
  root: {
    backgroundColor: colors.button.bg,
    color:  colors.button.color,
    fontWeight: '600'
  }
}))

const Button = (props) => {
  const { componentType, title, buttonType, ...restProps } = props
  const classes = useStyles()

  if (componentType === 'float') {
    return (<Tooltip title={title}>
      <MUiFab {...restProps}></MUiFab>
    </Tooltip>)
  }

  if (componentType === 'icon') {
    return (<Tooltip title={title}>
      <IconButton {...restProps}></IconButton>
    </Tooltip>)
  }

  return (<Tooltip title={title}>
    <MUiButton classes={buttonType === 'tab' ? null : classes} {...restProps}></MUiButton>
  </Tooltip>)
}

export default Button