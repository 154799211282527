import React from 'react'

import Button from '../components/Button'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import TextField from '../components/TextField';
import Typography from '../components/Typography'
import { makeStyles } from '@material-ui/core/styles';
import colors from '../variables/colors';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

const useStyles = makeStyles( theme => ({
  form: {
    backgroundColor: colors.primary,
    padding: '10px',
    maxWidth: '800px',
    borderRadius: '5px',
    margin: '10px',

    '@media screen and (min-width: 599px)': {
      padding: '20px 40px',
      margin: '20px auto 40px',
    }
  },
  field: {
    width: '100%',
    margin: '20px 0'
  },
  radioField: {
    width: 'fit-content',
    margin: '20px auto',
    '@media screen and (min-width: 599px)': {
      display: 'inline-block',
      marginRight: '40px'
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '30px 0 20px'
  },
  button: {
    width: '100px'
  },
  heading: {
    padding: '40px 0 10px',
    textAlign: 'center'
  },
  select: {
    color: '#fff',
  },
  radioGroup: {
    display: 'flex !important',
    flexDirection: 'row !important'
  },
  radioTitle: {
    color: '#fff'
  }

}))

const inputs = [
  'name',
  'email',
  'phone',
  'country',
  'instrument',
  'type',
  'comments'
]
const EnquiryPage = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState('group class');
  const [type, setType] = React.useState('guitar');

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  return (
  <Layout>
    <SEO title='Enquiry Form' />
    <section className={classes.section}>
      <div className={classes.heading}>
        <Typography variant="h3">ENQUIRY FORM</Typography>
      </div>
      <form action="/send_mail.php" name="contactform" method="post" className={classes.form} onSubmit={(results) => {
        const valueFields = results.target
        const values = inputs.map(field => {
          return {
            [field]: valueFields[field].value
          }
        })
        console.log(values)
      }}>
        <div className={classes.field}>
          <TextField name={inputs[0]} label="Name" variant="outlined"></TextField>
        </div>
        <div className={classes.field}>
          <TextField name={inputs[1]} label="Email" variant="outlined"></TextField>
        </div>
        <div className={classes.field}>
          <TextField name={inputs[2]} label="Phone" variant="outlined"></TextField>
        </div>
        <div className={classes.radioField}>
          <Typography variant="body1" className={classes.radioTitle}>Instrument</Typography>
          <RadioGroup classes={{root: classes.radioGroup}} aria-label="instrument" name={inputs[4]} value={type} onChange={handleTypeChange}>
            <FormControlLabel value="guitar" control={<Radio />} label="Guitar" classes={{ root: classes.select}} />
            <FormControlLabel value="piano"control={<Radio />} label="Piano" classes={{ root: classes.select}} />
            <FormControlLabel value="vocal"control={<Radio />} label="Vocal" classes={{ root: classes.select}} />
          </RadioGroup>
        </div>
        <div className={classes.field}>
          <TextField name={inputs[6]} label="Comment" variant="outlined" multiline={true} rows="3"></TextField>
        </div>
        <div className={classes.buttonContainer}>
          <Button  className={classes.button} type="submit">SUBMIT</Button>
        </div>
      </form>
    </section>
  </Layout>
  )
}

export default EnquiryPage
