import React from 'react'
import MUiTooltip from '@material-ui/core/Tooltip';

const Tooltip = (props) => {
  const { title = '', ...restProps } = props 
  return (
    <MUiTooltip title={title} {...restProps}></MUiTooltip>
  )
}

export default Tooltip