import React from 'react';
import MUiTextField from '@material-ui/core/TextField';
import Typography from '../Typography'
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../variables/colors'

const useStyles = makeStyles( theme => ({
  label: {
    color: colors.textfield.label,
  },
  root: {
    width: '100%',
    borderRadius: '5px',
    overflow: 'hidden',
    backgroundColor: 'white !important'
  }
}))

const TextField = (props) => {
  const { label, ...restProps } = props
  const classes = useStyles();
  return (<div>
    <Typography variant="body2" className={classes.label}>{label}</Typography>
    <MUiTextField 
      classes={{
        root: classes.root
      }}
      InputLabelProps = {{ style: { fontSize: '0.8rem' } }}
      inputProps={{ style: { fontSize: '0.9rem', backgroundColor: 'white' } }}
      size="small"
      {...restProps} />
  </div>
  )
}

export default TextField